<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Información del usuario</span>
        <small class="ml-5" v-if="user.isFucked">(Usuario bloqueado)</small>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <p class="font-weight-bold">Nombre del usuario</p>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(`${user.name} ${user.surname}`)"
                    >{{ user.name }} {{ user.surname }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <p class="font-weight-bold">Teléfono</p>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(user.phone)"
                    >{{ user.phone }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <p class="font-weight-bold">Correo electrónico</p>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(`${user.email}`)"
                    >{{ user.email }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <p class="font-weight-bold">Fecha de registro</p>
              <span>{{ user.created | filterDate }}</span>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <p class="font-weight-bold">Compras realizadas</p>
              {{ user.orderCounter }} compras
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                v-if="user.isFucked"
                rounded
                block
                @click="showDialogDisabled()"
                class="flex-grow-1 white--text mt-5"
                :elevation="0"
                :minHeight="56"
                color="green darken-4"
              >
                Desbloquear usuario
              </v-btn>

              <v-btn
                color="red darken-4"
                rounded
                class="flex-grow-1 white--text mt-5"
                block
                :elevation="0"
                :minHeight="56"
                v-else
                @click="showDialogDisabled()"
              >
                Bloquear usuario
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog max-width="400" v-model="dialogDisabled">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="dialogDisabled = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-overlay class="loading-center" :value="suspending">
          <lottie
            :options="defaultOptions"
            :height="300"
            :width="400"
            v-on:animCreated="handleAnimation"
          />
        </v-overlay>

        <v-card-text>
          <h3>
            ¿Está seguro que desea
            {{ user.isFucked ? "habilitar" : "suspender" }} el acceso a
            <span class="font-weight-bold"
              >{{ user.name }} {{ user.surname }}</span
            >?
          </h3>
          <p v-if="user.isFucked" class="mt-3 red--text font-weight-bold">
            El usuario podrá iniciar sesión después de realizar esta acción
          </p>
          <p v-else class="mt-3 red--text font-weight-bold">
            La sesión actual del usuario se cerrará y no podrá iniciar
            nuevamente
          </p>
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="green darken-4"
            v-if="user.isFucked"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="disabledAccount()"
          >
            Desbloquear usuario
          </v-btn>
          <v-btn
            color="red darken-4"
            v-else
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="disabledAccount()"
          >
            Bloquear usuario
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";

export default {
  name: "user-information",
  props: ["user"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      suspending: false,
      snackbar: false,
      snackbarText: "",
      dialogDisabled: false,
    };
  },
  computed: {
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("L");
    },
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    showDialogDisabled() {
      this.dialogDisabled = true;
    },
    async disabledAccount() {
      this.suspending = true;
      let token = await this.getSectionToken;

      let data = {
        uid: this.user.uid,
        disabled: !this.user.isFucked,
        key: this.user[".key"],
      };

      var httpUsersV2Disabled = fb
        .functions()
        .httpsCallable("httpUsersV2Disabled");
      httpUsersV2Disabled(data)
        .then((result) => {
          this.snackbarText = `Cuenta de usuario ${
            data.disabled ? "suspendida" : "habilitada"
          } exitosamente.`;
          this.snackbar = true;
          this.suspending = false;
          this.dialogDisabled = false;
        })
        .catch(() => {
          this.suspending = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>

