<template>
  <v-container fluid>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8">
        <h1>Validación de Tarjetas</h1>
      </v-col>

      <v-col cols="12" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="Filtrar por fecha"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              hide-details
              :disabled="loading"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                outlined
                color="primary"
                @click="modal = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  updateDateFilter();
                "
              >
                Filtrar
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="cardValidation"
          :search="$store.state.search"
          :items-per-page="10"
          :loading="loading"
          :sort-by="['createdAt']"
          :sort-desc="true"
          item-key=".key"
          fixed-header
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.validated`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { validated: item.validated },
                  item['.key'],
                  item
                )
              "
              v-model="item.validated"
            ></v-switch>
          </template>

          <template v-slot:[`item.deleted`]="{ item }">
            <v-switch disabled v-model="item.deleted"></v-switch>
          </template>

          <template v-slot:[`item.brand`]="{ item }">
            {{ item.brand }}/{{ item.type }}
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.validThru`]="{ item }">
            {{ item.validThru | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="getUser(item.userId)" small color="primary"
              >General</v-btn
            >
            <v-btn class="ml-3" @click="viewOrders(item)" small color="primary">
              Compras
            </v-btn>
            <v-btn
              class="ml-3"
              @click="editAddresses(item)"
              small
              color="primary"
            >
              Direcciones
            </v-btn>
          </template>

          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="showUser" max-width="700px">
      <user-information
        :key="randomKey"
        @cancel="showUser = false"
        :user="user"
      ></user-information>
    </v-dialog>
    <v-dialog v-if="userSelected" v-model="modalViewOrders" max-width="90%">
      <user-orders
        @cancel="modalViewOrders = false"
        :key="userSelected['.key']"
        :user="userSelected"
      ></user-orders>
    </v-dialog>
    <v-dialog v-if="userSelected" v-model="dialogViewAddresses" max-width="90%">
      <user-addresses
        @cancel="dialogViewAddresses = false"
        :key="userSelected['.key']"
        :user="userSelected"
      ></user-addresses>
    </v-dialog>
  </v-container>
</template>
<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions } from "vuex";
import userInformation from "./user-information";
import userOrders from "../users/user-orders";
import userAddresses from "../users/user-addresses";

export default {
  components: {
    Lottie,
    userOrders,
    userAddresses,
    userInformation,
  },
  mixins: [lottieSettings],
  data() {
    return {
      randomKey: 0,
      saving: false,
      itemSelected: null,
      search: "",
      cardValidation: [],
      snackbarText: "",
      modal: null,
      date: [
        `${new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
          .toISOString()
          .substr(0, 10)}`,
        `${moment().toDate().toISOString().substr(0, 10)}`,
      ],
      loading: true,
      snackbar: false,
      headers: [
        {
          text: "Nombre",
          value: "name",
          width: "250px",
          class: "nowrap",
        },
        {
          text: "Token",
          value: "tokens.bac",
          class: "nowrap",
        },
        {
          text: "Válida hasta",
          value: "validThru",
          class: "nowrap",
        },
        {
          text: "Tarjeta activa",
          value: "active",
          class: "nowrap",
        },
        {
          text: "Tarjeta validada",
          value: "validated",
          class: "nowrap",
        },
        {
          text: "Borrada",
          value: "deleted",
          class: "nowrap",
        },
        {
          text: "Creación",
          value: "createdAt",
          class: "nowrap",
        },
        {
          value: "options",
          width: "400px",
          align: "end",
          sortable: false,
        },
      ],
      user: {},
      showUser: false,
      userSelected: null,
      modalEditCards: false,
      modalBlockUser: false,
      modalViewOrders: false,
      dialogViewAddresses: false,
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("L");
    },
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    date: function (val) {
      if (val.length > 1) {
        this.cardValidation = [];
        this.loading = true;
        this.$binding(
          "cardValidation",
          db
            .collection("cardValidation")
            .where(
              "createdAt",
              ">",
              moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
            )
            .where(
              "createdAt",
              "<",
              moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
            )
        ).then(() => {
          this.loading = false;
        });
      }
    },
  },

  methods: {
    title() {
      return "Selecciona las fechas a filtrar";
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },
    ...mapActions(["addBusiness"]),
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    editAddresses(card) {
      db.collection("users")
        .doc(card.userId)
        .get()
        .then((response) => {
          let user = response.data();
          user[".key"] = response.id;
          this.userSelected = user;
          this.dialogViewAddresses = true;
        });
    },
    viewOrders(card) {
      db.collection("users")
        .doc(card.userId)
        .get()
        .then((response) => {
          let user = response.data();
          user[".key"] = response.id;
          this.userSelected = user;
          this.modalViewOrders = true;
        });
    },

    getUser(userId) {
      this.$binding("user", db.collection("users").doc(userId)).then((user) => {
        this.randomKey = Math.random();
        this.showUser = true;
      });
    },

    getCards() {
      this.cardValidation = [];
      this.loading = true;
      db.collection("cardValidation")
        .where(
          "createdAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "createdAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        )
        .get()
        .then((response) => {
          response.forEach((item) => {
            let card = item.data();
            card[".key"] = item.id;
            this.cardValidation.push(card);
          });
          this.loading = false;
        });
    },

    async switchControlChanged(data, cardId, item) {
      if (data && cardId) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.$store.state.user[".key"];
        this.saving = true;
        db.collection("cardValidation")
          .doc(cardId)
          .update(data)
          .then(async (ref) => {
            await this.updateUserCardsSubcollection(data, cardId, item);
            this.saving = false;
            this.snackbarText =
              "Información de tarjeta actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            if (data.hasOwnProperty("active")) item.active = !item.active;
            else if (data.hasOwnProperty("validated"))
              item.validated = !item.validated;
          });
      }
    },
    updateUserCardsSubcollection(data, cardId, item) {
      return new Promise((resolve, reject) => {
        db.collection("users")
          .doc(item.userId)
          .collection("cards")
          .where("shortId", "==", item.shortId)
          .get()
          .then((card) => {
            if (card.docs.length > 0) {
              let idUserCard = card.docs[0].id;
              db.collection("users")
                .doc(item.userId)
                .collection("cards")
                .doc(idUserCard)
                .update(data)
                .then((ref) => {
                  resolve("success");
                })
                .catch((err) => {
                  reject("failed");
                });
            } else {
              reject("failed");
            }
          })
          .catch((err) => {
            reject("failed");
          });
      });
    },
  },
  mounted() {
    // document
    //   .getElementsByClassName("v-data-table__wrapper")[0]
    //   .addEventListener("scroll", function(e) {
    //     document.body.scrollTop = document.documentElement.scrollTop = document.getElementsByClassName(
    //       "v-data-table__wrapper"
    //     )[0].scrollTop;
    //   });
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "Tarjeta";
    this.$store.state.search = "";
    this.getCards();
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
