var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"absolute":"","top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-overlay',{staticClass:"loading-center",attrs:{"value":_vm.saving}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":150,"width":150},on:{"animCreated":_vm.handleAnimation}})],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',[_vm._v("Validación de Tarjetas")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Filtrar por fecha","prepend-icon":"fa-calendar-alt","readonly":"","filled":"","rounded":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","title-date-format":_vm.title,"scrollable":"","max":_vm.today},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.date);
                _vm.updateDateFilter();}}},[_vm._v(" Filtrar ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cardValidation,"search":_vm.$store.state.search,"items-per-page":10,"loading":_vm.loading,"sort-by":['createdAt'],"sort-desc":true,"item-key":".key","fixed-header":"","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                { active: item.active },
                item['.key'],
                item
              )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:`item.validated`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                { validated: item.validated },
                item['.key'],
                item
              )}},model:{value:(item.validated),callback:function ($$v) {_vm.$set(item, "validated", $$v)},expression:"item.validated"}})]}},{key:`item.deleted`,fn:function({ item }){return [_c('v-switch',{attrs:{"disabled":""},model:{value:(item.deleted),callback:function ($$v) {_vm.$set(item, "deleted", $$v)},expression:"item.deleted"}})]}},{key:`item.brand`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.brand)+"/"+_vm._s(item.type)+" ")]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterDate")(item.createdAt))+" ")]}},{key:`item.validThru`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterDate")(item.validThru))+" ")]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.getUser(item.userId)}}},[_vm._v("General")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.viewOrders(item)}}},[_vm._v(" Compras ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editAddresses(item)}}},[_vm._v(" Direcciones ")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"inner-lottie"},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.showUser),callback:function ($$v) {_vm.showUser=$$v},expression:"showUser"}},[_c('user-information',{key:_vm.randomKey,attrs:{"user":_vm.user},on:{"cancel":function($event){_vm.showUser = false}}})],1),(_vm.userSelected)?_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.modalViewOrders),callback:function ($$v) {_vm.modalViewOrders=$$v},expression:"modalViewOrders"}},[_c('user-orders',{key:_vm.userSelected['.key'],attrs:{"user":_vm.userSelected},on:{"cancel":function($event){_vm.modalViewOrders = false}}})],1):_vm._e(),(_vm.userSelected)?_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.dialogViewAddresses),callback:function ($$v) {_vm.dialogViewAddresses=$$v},expression:"dialogViewAddresses"}},[_c('user-addresses',{key:_vm.userSelected['.key'],attrs:{"user":_vm.userSelected},on:{"cancel":function($event){_vm.dialogViewAddresses = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }